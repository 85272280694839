// Overwrite the endpoints of the .app layer
// TODO: use enums for this to prevent issues caused by typos
// and should we use plural or singular for the resource names?
export const endpoints: Record<ResourceName, EndpointPath> = {
  availability: '/api/v1/availabilities/',
  contract: '/api/v1/contracts/',
  contract_template: '/api/v1/contract_templates/',
  form: '/api/v1/forms/',
  form_submission: '/api/v1/form_submissions/',
  group: '/api/v1/groups/',
  import_group: '/api/v1/import_groups/',
  internship: '/api/v1/internships/',
  internship_period: '/api/v1/internship_periods/',
  mentorship: '/api/v1/mentorships/',
  notification_template: '/api/v1/notification_templates/',
  organization: '/api/v1/organizations/',
  page: '/api/v1/cms/pages/',
  provider: '/api/v1/organizations/',
  user: '/api/v1/current_user/',
  stock_image: '/api/v1/stock_images/',
}
